/* App.css */

body {
    font-family: Roboto,arial,sans-serif;
    font-size: 14px; /* Google uses small font sizes for search results */
    color: #202124; /* Google's text color is a very dark grey */
    background-color: #fff; /* White background */
    margin: 0;
    padding: 0;
  }
  
  #root {
    display: flex;
    justify-content: center;
    padding-top: 20px; /* Adding some padding at the top */
  }

  .App {
    max-width: 700px; /* Or the width you prefer */
    margin: 20px auto; /* Top and bottom margin with auto horizontal margins centers the div */
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* A subtle shadow for depth */
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally within the flex container */
    justify-content: center; /* Center children vertically (if there's enough vertical space) */
  }
  
  .search-result {
    position: relative;
    width: 600px;
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .search-result-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .favicon {
    width: 24px;
    height: 24px;
    color: #0060f0;
    margin-bottom: 8px;
    margin-right: 8px;
    position: relative;
    display: flex;
    background-color: #f1f3f4;
    border: 1px solid #ecedef;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .favicon img {
    height: 24px;
  }

  .uploader-hover {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Light overlay */
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-left: 48px;
  }
  
  .favicon:hover .uploader-hover {
    display: flex;
  }
  
  .header-texts {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
  }
  
  .site-name, .breadcrumbs, .title, .description {
    width: 100%;
    border: none;
  }
  
  .site-name {
    font-size: 14px;
    color: #202124;
    line-height: 20px;
    width: 500px;
  }
  
  .breadcrumbs {
    font-size: 12px;
    color: #4d5156;
    line-height: 18px;
    width: 500px;
  }

  .title, .measure {
    font-size: 20px;
    font-weight: 400;
    color: #1a0dab;
    padding-top: 5px;
    }
    
    .description, .description-measure {
      font-family: Roboto;
      font-size: 14px;
      color: #333;
      line-height: 1.4;
      overflow: hidden;
      background: none;
    }
    
    /* Style the input fields to appear seamless within the text */
    input, textarea {
    outline: none;
    border: none;
    color: inherit;
    background-color: transparent;
    }
    
    /* Apply focus styles for when the inputs are being edited */
    input:focus, textarea:focus {
    background-color: #EAEBF6;
    }
    
    /* Placeholder styles for empty editable fields */
    ::placeholder {
    color: #bbb;
    }
    
    /* Adjust the padding as necessary to align text properly */
    input, textarea {
    padding-left: 2px;
    }
    
    /* Remove resize handle from textarea */
    textarea {
    resize: none;
    }

    .character-countdown {
      position: absolute;
      top: 17%;
      right: 6px;
      padding: 12px 10px 12px 5px;
      opacity: 0; /* Start invisible, animate to visible */
    }
    
    .description-countdown {
      position: absolute;
      bottom: -10%;
      right: 2px;
      padding: 10px 15px 22px 5px;
      opacity: 0; /* Start invisible, animate to visible */
    }

    .measure {
      visibility: hidden; /* Hide the div from view */
      white-space: nowrap; /* Ensure the text doesn't wrap */
      position: absolute; /* Take out of document flow */
      pointer-events: none; /* Ignore mouse events */
    }

    .favicon-upload-placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: #f0f0f0;
      cursor: pointer;
      font-size: 18px;
      color: #333;
      text-align: center;
      line-height: 28px; /* Align the arrow vertically */
    }

    .my-locale-override {
      --l10n-upload-files: 'Upload favicon';
      --l10n-drop-files-here: 'Drop favicon here';
    }

    .tips-container {
      background-color: #ffffff; /* White background */
      border-radius: 16px; /* Rounded corners */
      padding: 20px; /* Spacing inside the container */
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
      margin-top: 20px; /* Space from the search result */
      width: 100%;
      box-sizing: border-box;
    }
    
    .tip-card {
      background-color: #f9f9f9; /* Light background for cards */
      border-radius: 12px; /* Rounded corners for cards */
      margin-bottom: 16px; /* Space between cards */
      padding: 16px; /* Padding inside cards */
      display: flex; /* Align icon and text side by side */
      align-items: center; /* Center items vertically */
    }
    
    .icon-container {
      border-radius: 50%; /* Make it round */
      margin-right: 16px; /* Space after the icon container */
    }
    
    /* Add specific background colors and icons for each tip */
    .keyword-icon {
      width: 88px;
      height: 40px;
      background-color: #b3e5fc; /* Light blue */
      background-image: url("https://metadata-sbf3.onrender.com/tip-1.svg");
      background-repeat: no-repeat;
      background-size: 50px 50px;
    }
    
    .build-icon {
      width: 40px;
      height: 40px;
      background-color: #f3e5f5; /* Light purple */
      background-image: url("https://metadata-sbf3.onrender.com/tip-2.svg");
      background-repeat: no-repeat;
      background-size: 50px 50px;
    }
    
    .limit-icon {
      width: 66px;
      height: 40px;
      background-color: #ffccbc; /* Light red */
      background-image: url("https://metadata-sbf3.onrender.com/tip-3.svg");
      background-repeat: no-repeat;
      background-size: 50px 50px;
    }
    
    .hands-on-help {
      text-align: center; /* Center the text */
      padding-top: 16px; /* Space from the last card */
    }
    
    .consultation-button {
      background-color: #000; /* Blue background */
      color: white; /* White text */
      border: none; /* No border */
      border-radius: 20px; /* Rounded corners for button */
      padding: 10px 20px; /* Padding inside the button */
      margin-top: 16px; /* Space from the help text */
      cursor: pointer; /* Pointer cursor on hover */
      font-size: 16px; /* Button font size */
      display: inline-block; /* Needed for margin to take effect */
    }
    
    .tip-card h3 {
      margin: 0; /* No margin for the heading */
      color: #333; /* Dark text for the heading */
      font-size: 16px; /* Font size for the heading */
    }
    
    .tip-card p {
      margin: 4px 0 0 0; /* Margin for the paragraph */
      color: #666; /* Dark grey text for the paragraph */
      font-size: 14px; /* Font size for the paragraph */
    }
    
    .add-search-result-button {
      width: 640px;
      padding: 10px;
      margin-top: 10px;
      font-size: 16px;
      cursor: pointer;
      background-color: #f1f3f4; /* Light grey for discretion */
      border: none;
      border-radius: 18px;
    }

    .add-search-result-button:hover {
      background-color: #D8D7DC;
    }
    
    .remove-button {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      color: #ccc;
      font-size: 16px;
      font-weight: bold;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      opacity: 0; /* Start invisible */
      transition: opacity 0.3s; /* Smooth transition for opacity */
    }
    
    .search-result:hover .remove-button, .search-result:hover .title-case-button, .search-result:hover .date-button, .search-result:hover .stars-button {
      opacity: 1; /* Visible on hover */
    }

    .title-case-button {
      position: absolute;
      top: 10px;
      right: 40px; /* Adjust based on the size of the buttons */
      cursor: pointer;
      width: 20px;
      height: 20px;
      background-size: cover;
      background-position: center;
      opacity: 0; /* Start invisible */
      transition: opacity 0.3s; /* Smooth transition for opacity */
    }

    .date-button {
      position: absolute;
      top: 10px;
      right: 80px; /* Adjust based on the size of the buttons */
      cursor: pointer;
      width: 20px;
      height: 20px;
      background-size: cover;
      background-position: center;
      opacity: 0; /* Start invisible */
      transition: opacity 0.3s; /* Smooth transition for opacity */
    }

    .stars-button {
      position: absolute;
      top: 10px;
      right: 120px; /* Adjust based on the size of the buttons */
      cursor: pointer;
      width: 20px;
      height: 20px;
      background-size: cover;
      background-position: center;
      opacity: 0; /* Start invisible */
      transition: opacity 0.3s; /* Smooth transition for opacity */
    }

    .stars {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>");
      background-repeat: repeat-x;
      display: inline-block;
      background-size: 14px 11.4px;
      height: 11.4px;
      width: 68px;
      overflow: hidden;
      padding-right: 3px;
    }

    .uo4vr {
      color: #70757a;
      line-height: 1.58;
  }